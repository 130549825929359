<template>
  <div>
    <b-row>
        <b-col
          md="12"
          class="mb-3 text-right"
        >
          <b-button
            class=""
            variant="outline-primarySpore"
            @click="showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            <span class="align-middle" v-if="!show"> Mostrar filtros </span>
            <span class="align-middle" v-else > Ocultar filtros </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body v-if="show">
        <b-card-header class="pb-50">
          <h5>
            Filtrar
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Nombre"
              >
                <b-form-input
                id="firstName"
                v-model="filters.firstName"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Apellido"
              >
                <b-form-input
                id="lastName"
                v-model="filters.lastName"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Correo Electrónico"
              >
                <b-form-input
                id="email"
                v-model="filters.email"
                type="email"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Tipo"
              >
                <b-form-select
                  id="type"
                  v-model="filters.type"
                  :options="userTypes"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-form-group
                label="."
                style="color:#FFFFFF"
              >
                <b-button
                  variant="primarySpore"
                  @click="search"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span>Buscar</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  @click="clearSearch"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Limpiar</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col md="6" class="mb-3 text-left">
          <b-col cols="12" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="params.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
              <label>Registros</label>
          </b-col>
        </b-col>
        <b-col cols="12" md="6" class="custom-search d-flex justify-content-end">
          <div class="d-flex align-items-center">
            <b-button class="m-1" variant="primarySpore" :to="`/users/add`" v-b-tooltip.hover.top title="Agregar">
              <feather-icon icon="PlusCircleIcon"/>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start">
          <h4>Total de usuarios: {{ totalRows }}</h4>
        </b-col>
      </b-row>
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <b-table :fields="fields" :items="responseUser" responsive tbody-tr-class="shadow-sm">
           <template #cell(firstName)="data">
            <listItem :value="data.item.firstName" description="Nombre(s)" img="contact"/>
          </template>
          <template #cell(lastName)="data">
            <listItem :value="data.item.lastName" description="Apellido(s)"/>
          </template>
          <template #cell(email)="data">
            <listItem :value="data.item.email" description="Correo Electrónico" img="email"/>
          </template>
          <template #cell(type)="data">
            <listItem :value="(data.item.type === 'A' ? 'Administrador' : data.item.type === 'C' ? 'Administrador CFE' : data.item.type === 'S' ? 'Supervisor' : data.item.type === 'I' ? 'Instalador' : '')" description="Tipo" img="alias"/>
          </template>
          <template #cell(uuid)="data">
            <b-row>
              <b-col cols="12" md="12" class="custom-search d-flex justify-content-end">
                <div class="d-flex align-items-center">
                  <b-button v-b-tooltip.hover.left title="Editar" variant="primarySpore" :to="`/users/update/${data.value}`">
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                </div>
                &nbsp;
                <div class="d-flex align-items-center">
                  <b-button v-b-tooltip.hover.top title="Eliminar" variant="danger" @click="confirmDelete(data.value)">
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
      </b-table>

      <div v-if="responseUser.length == 0" class="noData">
        {{$t('Messages.NoData')}}
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.params.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>

          </b-col>

        </b-row>
      </div>
    </div>
  </b-card>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import listItem from '@core/spore-components/listItem/listItem.vue'
// import { empty } from '@/libs/tools/helpers'
import {
  BCard, BButton, BRow, BCol, BTable, BPagination, BCardHeader, BCardBody, BFormGroup, BFormInput, BFormSelect
} from 'bootstrap-vue'

const defaultFilters = {
  firstName: '',
  lastName: '',
  email: '',
  type: ''
}

export default {
  components: {
    BCard, BButton, BRow, BCol, listItem, BTable, BPagination, BCardHeader, BCardBody, BFormGroup, BFormInput, BFormSelect
  },
  data () {
    return {
      show: false,
      responseUser: [],
      fields: [
        { key: 'firstName', sortable: true, label: 'Nombre(s)' },
        { key: 'lastName', sortable: true, label: 'Apellido(s)' },
        { key: 'email', label: 'Correo Electrónico', sortable: true },
        { key: 'type', label: 'Tipo', sortable: true },
        { key: 'uuid', thClass: 'text-right', label: 'Acciones' }
      ],
      userTypes: [
        { value: '', text: 'Todos' },
        { value: 'C', text: 'Administrador CFE' },
        { value: 'S', text: 'Supervisor' },
        { value: 'I', text: 'Instalador' }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      count: 0,
      params: {
        limit: 20,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        divisionUuid: this.$store.state.session.AppActiveUser().division.uuid
      },
      filters: { ...defaultFilters }
    }
  },

  methods: {
    showFilter () {
      this.show = !this.show
    },
    async getResponse () {
      const filtersNotEmpty = Object.fromEntries(Object.entries(this.filters).filter(([_key, value]) => value !== ''))
      const payload = {
        url: 'users/',
        method: 'GET',
        params: { ...this.params, filters: JSON.stringify(filtersNotEmpty) }
      }
      await request(payload).then(data => {
        this.responseUser = data.data.data.rows
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      }).catch(() => {
        this.responseUser = []
      })
    },
    search () {
      this.params.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clearSearch () {
      this.params.offset = 0
      this.currentPage = 1
      this.filters = { ...defaultFilters }
      this.getResponse()
    },
    confirmDelete (uuid) {
      this.$swal({
        title: '¿Deseas eliminar el registro?',
        text: 'Esperando confirmación del usuario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const payload = {
            url: `users/${uuid}`,
            method: 'DELETE'
          }
          await request(payload).then(response => {
            this.getResponse()
          })
        }
      })
    }
  },

  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.params.offset = (val - 1) * this.params.limit
      this.getResponse()
    },
    byColumn (val) {
      this.params.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Shares.scss";
</style>
